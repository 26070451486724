/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getEmployeeType(data) {

    return api.get(`employee_type/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getAll() {

    return api.get(`employee_type/get_all`)
  },

  showEmployeeType(data){
    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  employeeTypeStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`employee_type/store`, params, {})
  },

  employeeTypeUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`employee_type/update/${data.employee_type_id}`, params, {})
  },

  employeeTypeDestroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.delete(`employee_type/delete/${data.employee_type_id}`, params, {})
  },

}
