<template>
  <v-card flat>
    <v-card-text>
      <h2 class="mb-3">บทบาท</h2>
      <hr/>
      <h4 class="mt-3 mb-3">กลุ่มพนักงาน</h4>
      <v-select
        class="mb-5"
        outlined
        dense
        hide-details
        label="กรุณาเลือก"
        item-text="employee_type_name"
        item-value="employee_type_id"
        v-model="employee_type_id"
        :items="employee_types"
        @change="change_employee_type"
      ></v-select>


      <small
        style="margin-top: 100px;"
        class="validate-err"
        v-for="(err,index) in v$.employee_type_id.$silentErrors"
        v-if="v$.employee_type_id.$error">{{ index > 0 ? ', ' : '' }} {{ err.$message }}</small>

      <h4
        v-if="employee_type_id"
        class="mt-3 mb-3">เลือกบทบาท</h4>
      <treeselect
        v-if="employee_type_id"
        class="mb-5"
        outlined
        dense
        hide-details
        label="กรุณาเลือก"
        item-text="label"
        item-value="id"
        v-model="role_ids" :multiple="true" :options="roles"/>

      <small
        class="validate-err"
        v-for="(err,index) in v$.role_ids.$silentErrors"
        v-if="v$.role_ids.$error">{{ index > 0 ? ', ' : '' }} {{ err.$message }}</small>

      <hr class="mt-5 mb-5">

      <v-btn
        :disabled="!employee_type_id"
        style="margin-top: 15px;" color="primary" class="button_size" @click="update">
        บันทึก
      </v-btn>
      <!--      <hr class="mt-5 mb-3" />-->
    </v-card-text>
  </v-card>
</template>

<script>
import {mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline} from '@mdi/js'
import {ref} from '@vue/composition-api'
import instance_role from '../../../services/role'
import instance_employee_type from '../../../services/employeeType'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Swal from 'sweetalert2'
import {useVuelidate} from '@vuelidate/core'
import {required, email} from '@vuelidate/validators'
// demos
import AccountSettingsAccount from './backend.vue'
import instance_order from "@/services/order";
import user from "@/services/user";
// import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
// import AccountSettingsInfo from './AccountSettingsInfo.vue'

export default {
  components: {
    Treeselect,
    Swal
    // backend
    // AccountSettingsAccount,
    // AccountSettingsSecurity,
    // AccountSettingsInfo,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      {title: 'บทบาท', icon: mdiAccountOutline},
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }


    return {
      tab,
      tabs,
      v$: useVuelidate(),
      // accountSettingData,
      // icons: {
      //   mdiAccountOutline,
      //   mdiLockOpenOutline,
      //   mdiInformationOutline,
      // },
    }
  },

  data() {
    return {
      employee_type_id: null,
      employee_types: [],
      role_ids: [],
      roles: []
    }
  },
  validations() {
    return {
      employee_type_id: {required,},
      role_ids: {required,},
    }
  },
  methods: {
    change_employee_type() {
      this.$store.state.isLoading = true;
      var data = {
        id: this.employee_type_id
      }
      instance_role.getRole(data)
        .then(res => {
          if (res.status === 200) {
            this.role_ids = res.data.data

            this.$store.state.isLoading = false
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
    initAllRole() {
      instance_role.getAllRole({id: 1}).then(res => {
        this.roles = res.data.children
      }).catch(err => {

      })
    },
    initData() {
      instance_employee_type
        .getAll()
        .then(res => {
          if (res.status === 200) {
            this.employee_types = res.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })

    },
    async update() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true;
      var data = {
        id: this.employee_type_id,
        role: this.role_ids
      }
      // this.employee_type_select.employee_type_id
      instance_role
        .roleUpdate(data)
        .then(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })

            this.$store.state.isLoading = false
          }
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: error.message,
          })
          this.$store.state.isLoading = false;
        })
    },
  },
  mounted() {
    this.initData();
    this.initAllRole();
  }

}
</script>
