/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

const baseURL = `${process.env.VUE_APP_BASEURL}`
export default {
  getOder(data) {
    // const params = new URLSearchParams()
    // params.append('search', data.search)
    // params.append('date', data.date)
    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.status}&status2=${data.status2}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportSell(data) {
    return api.get(`report/get_sell?search=${data.search}&date=${data.date}&type=${data.type}&type2=${data.type2}`)
  },
  getReportSellTable(data) {
    return api.get(`report/get_product_sell?search=${data.search}&date=${data.date}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getReportBookingOrderDetail(order_id) {
    return api.get(`report_booking_order/get_report_booking_order/${order_id}`)
  },

  updateBookingOrder(data, details = []) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    params.append('details', JSON.stringify(details))
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`report_booking_order/update_detail/${data.order_id}`, params, config, {})
  },

  getOrderReport(data) {
    return api.get(`getOrderReport?search=${data.search}&start_date=${data.date ? data.date[0] : ''}&end_date=${data.date[1]}&status=${data.status}`)
  },
  getStatusOrder() {
    return api.get('/getStatusOrder')
  },
  delete(id) {
    return api.delete(`order/delete${id}`)
  },
  createdOrder() {
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('/createdOrder', config, {})
  },

  showOrder(member_id) {
    return api.get(`/apiShowByMember/${member_id}`)
  },
  getOrderDetail(order_id) {
    return api.get(`get_order_details/${order_id}`)
  },
  showOrderDetail(order_id = '') {
    return api.get(`showOrderDetail/${order_id}`)
  },
  getPendingOrder() {
    return api.get('get_pending_order')
  },
  orderUpdatePayment(data, tmp) {
    const getFormData = data

    const params = new FormData()
    params.append('total_importune', tmp.total_importune)
    params.append('orders_configs', tmp.orders_configs)
    params.append('totalConfigs', tmp.totalConfigs)

    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('/orderUpdatePayment', params, config, {})
  },
  orderUpdate(data) {
    const getFormData = data

    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('/orderUpdate', params, config, {})
  },
  orderUpdatePaid(data) {
    const getFormData = data

    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('/orderUpdatePaid', params, config, {})
  },
  orderDetailDelivery(data) {
    const getFormData = data
    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (getFormData[key]) {
          params.append(key, getFormData[key])
        }
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('/orderDetailDelivery', params, config, {})
  },

  /// ////////////// DETAIL Page /////////

  uploadSlip(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/uploadSlip/${data.order_id}`, params, config, {})

    // action="{{url('admin/print_envelope/').'/'.$order->order_id}}"
  },
  orderEnvelope(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/problem/${data.id}`, params, config, {})

    // action="{{url('admin/print_envelope/').'/'.$order->order_id}}"
  },

  orderInstallment(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`paymentagainInstallment/${data.order_id}`, params, config, {})

    // <a href="/paymentagainInstallment/{{$order->order_id}}">
  },

  orderTax(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/update_tax/${data.order_id}`, params, config, {})

    // action="{{url('admin/update_tax/id').'/'.$order->order_id}}"
  },
  orderConfirm(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/uploadOrderConfirm/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/uploadOrderConfirm').'/'.$order->order_id}}"
  },
  confirmManufacturing(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/confirmManufacturing/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/uploadOrderConfirm').'/'.$order->order_id}}"
  },
  orderCancel(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/uploadOrderCanceled/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/uploadOrderCancel').'/'.$order->order_id}}"
  },
  prepareToShip(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/updatePrepareToShip/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/updatePrepareToShip').'/'.$order->order_id}}">
  },

  prepareToShipAll(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (let i = 0; i < data.length; i++) {
      params.append('order_id[]', data[i])
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post('order/updatePrepareToShip/0', params, config, {})

    // <a href="{{url('admin/updatePrepareToShip').'/'.$order->order_id}}">
  },
  updateTax(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`report_booking_order/update_tax/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/updatePrepareToShip').'/'.$order->order_id}}">
  },
  updateFinish(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/update_finish/${data.order_id}`, params, config, {})

    // <a href="{{url('admin/updatePrepareToShip').'/'.$order->order_id}}">
  },
  orderProblem(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/bill_problem/${data.order_id}`, params, config)

    // action="{{url('admin/bill_problem').'/'.$order->order_id}}"
  },

  checkUpdateData(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    return api.post(`order/checkUpdateData/${data.order_id}`, params, config)
  },
  getPrintInvoice(data) {
    return api.get(`order/print_invoice/${data.order_id}`)
  },
  getEnvelope(data) {
    return api.get(`order/print_envelope/${data.order_id}`)
  },
  getOrderInv(id) {
    return api.get(`order_scan_billing/get_order_inv/${id}`)
  },
  getOrderById(id) {
    return api.get(`order_scan_billing/get_order/${id}`)
  },
  getOrderTracking(id) {
    return api.get(`${baseURL.replace('/auth', '')}/ger_order_tracking/${id}`)
  },
}
