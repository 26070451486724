/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getEmployeeType(data) {
    return api.get(`employee_type/list`)
  },
  getRole(data) {

    return api.get(`role/show/${data.id}?role_type_id=1`)
  },
  getAllRole(data) {

    return api.get(`role/get_role/1`)
  },

  getEmployeeRole(data) {
    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  roleUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`role/update/${data.id}`, params, {})
  },
}
